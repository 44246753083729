import { useCallback } from 'react';

import { v4 as uuid } from 'uuid';

import { WebsiteVisitPayload } from 'src/api/zrm';
import useApi from 'src/hooks/useApi';
import useSettings from 'src/hooks/useSettings';
import logger from 'src/utils/logger';

const useWebsiteVisitCallback = () => {
  const { api } = useApi();
  const requestId = uuid();
  const { country } = useSettings();

  const websiteVisitCall = useCallback(async () => {
    const data: WebsiteVisitPayload = {
      landing_url: window.location.href,
      cookie_consent: null,
    };

    try {
      await api.v2.createWebsiteVisitEventV2VisitCountryPost(
        country,
        data,
        { headers: { 'X-Request-ID': requestId }, cancelToken: requestId },
      );
    } catch (error) {
      logger.error(error, {
        source: 'useWebsiteVisitCallback',
        description: 'Send Website Visit',
        requestId,
      });
    }
    logger.log('Website visit event sent');
  }, []);

  return websiteVisitCall;
};

export default useWebsiteVisitCallback;
